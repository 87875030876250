import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import {  useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import "./BlogDetails.css";
import "./Blog.css";
import NavbarBlog from "../BlogNavigaton/NavbarBlog";
import FooterBlog from "../BlogFooter/FooterBlog";

const SingleBlog = () => {
  const [singleBlogPost, setSingleBlogPost] = useState([]);

  let { id } = useParams();

  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN
  });

  useEffect(() => {
    const getEntryById = async () => {
      try {
        await client.getEntry(id).then((entries) => {
          setSingleBlogPost(entries);
        });
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
      }
    };
    getEntryById();
  }, [client, id]);

  return (
    <div  id="layout">
      <NavbarBlog />
      <div className="bd"></div>
       
      <div className="blog-detail">
      
        <div className="detail-tekst">
        <h1 className="naslov">{singleBlogPost?.fields?.naslovBloga}</h1>
        <p className="detail-end">
          <small>
            {singleBlogPost?.fields?.napraviDatum === undefined
              ? "loading"
              : new Intl.DateTimeFormat("en-GB", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                }).format(new Date(singleBlogPost?.fields?.napraviDatum))}
          </small>
          <small style={{fontSize: "12px"}}>Kategorija: {singleBlogPost?.fields?.category}</small>
        </p>
        <img
          src={singleBlogPost?.fields?.slikaBloga?.fields?.file?.url}
          title=""
          alt={singleBlogPost?.fields?.naslovBloga}
        />
        

        <div className="">
          <ReactMarkdown children={singleBlogPost?.fields?.sadrzajObjave} />
        </div>
        </div>
      </div>
      <div className="footer-others">
    <FooterBlog />
    </div>
    </div>
  );
};

export default SingleBlog;
