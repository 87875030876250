import React from "react";
import "./Footer.css";
import pulselogo from "../../assets/logo_transparent.png";
const Footer = () => {
  const handleClickScroll = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="pg-footer">
      <footer className="footer">
        <svg
          className="footer-wave-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 100"
          preserveAspectRatio="none"
        >
          <path
            className="footer-wave-path"
            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
          ></path>
        </svg>
        <div className="footer-content">
          <div className="footer-content-column">
            <div className="footer-logo">
              <a className="footer-logo-link" href="/">
                <img src={pulselogo} alt="bnaic" />
              </a>
            </div>
            <div className="footer-menu"></div>
          </div>
          <div className="footer-content-column">
            <div className="footer-menu">
              <h2 className="footer-menu-name"> PulseCode</h2>
              <ul id="menu-company" className="footer-menu-list">
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <button
                    className="about-button"
                    onClick={() => handleClickScroll("o-nama")}
                  >
                     O MENI{" "}
                  </button>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <button
                    className="about-button"
                    onClick={() => handleClickScroll("nase-usluge")}
                  >
                    NAŠE USLUGE
                  </button>
                </li>

                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <button
                    className="about-button"
                    onClick={() => handleClickScroll("tehnologije")}
                  >
                    TEHNOLOGIJE
                  </button>
                </li>

                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <button
                    className="about-button"
                    onClick={() => handleClickScroll("pristup")}
                  >
                    PRISTUP
                  </button>
                </li>

                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <button
                    className="about-button"
                    onClick={() => handleClickScroll("najnoviji-blogovi")}
                  >
                    NAJNOVIJI BLOGOVI
                  </button>
                </li>

                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <button
                    className="about-button"
                    onClick={() => handleClickScroll("kontakt")}
                  >
                    KONTAKT
                  </button>
                </li>
              </ul>
            </div>
            <div className="footer-menu"></div>
          </div>

          <div className="footer-content-column">
            <div className="footer-call-to-action">
              <h2 className="footer-call-to-action-title">Email</h2>
              <p className="footer-call-to-action-link-wrapper">
                pulsecodeweb@gmail.com
              </p>
            </div>
            <div className="footer-call-to-action">
              <h2
                className="footer-call-to-action-title"
                style={{ marginBottom: "10px" }}
              >
                Broj telefona
              </h2>

              <a
                className="footer-call-to-action-link-wrapper"
                href="tel:+38761338354"
                target="_self"
              >
                +38761338354
              </a>
            </div>
          </div>
          <div className="footer-social-links"></div>
        </div>
        <div className="footer-copyright">
          <div className="footer-copyright-wrapper">
          <div className="footer-copyright-text">
  <p>
    <a
      style={{
        color: "white",
        textDecoration: "none",
        marginRight: "2px",
        fontWeight: "bold",
      }}
      href="https://ehaportfolio.netlify.app/"
      target="_blank"
      rel="noreferrer"
    >
      {" "}
      Webmaster: EHA
    </a>
    &copy; {new Date().getFullYear()} PulseCode. All Rights Reserved.{" "}
  </p>
</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
