import React from "react";
import { PulseLoader } from "react-spinners";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <PulseLoader color="rgba(23, 86, 12, 1)" loading margin={4} size={30} />
    </div>
  );
};

export default Loader;
