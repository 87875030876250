import React, { useEffect } from "react";
import pulselaptop from "../../assets/plaptop.webp";
import "aos/dist/aos.css";
import Aos from "aos";
import "./Home.css";

const Home = () => {
  useEffect(() => {
    if (window.innerWidth > 768) {
      Aos.init({ duration: 3000 });
    }
  }, []);

  return (
    <>
      <div className="bg-pulse">
      <div className="main-home" >
        
        <div className="pulse">
          <div className="only-mobile">
         
            <h3>
              Dobrodošli u <span>PulseCode</span> - vašeg partnera u svijetu
              digitalnog stvaranja!
            </h3>
          </div>
          <div data-aos="fade-down" className="flex-item image-section">
            <img src={pulselaptop} alt="pulse-laptop" />
          </div>
          <div data-aos="fade-left" className="flex-item text-section">
            <div className="only-desktop">
            <hr className="separator" />
            <h3>
              Dobrodošli u <span>PulseCode</span> - vašeg partnera u svijetu
              digitalnog stvaranja!
            </h3>
            </div>
            

            <h4>
              Bez obzira jeste li početnik poduzetnik ili establirana kompanija,
              PulseCode posvećen je pretvaranju vaših ideja u funkcionalne,
              vizualno privlačne i visoko performantne web stranice.
            </h4>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Home;
