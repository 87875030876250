import React, { useState } from "react";
import "./NavbarBlog.css";
import { HashLink } from "react-router-hash-link";

const NavbarBlog = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`navbaranc visible ${isMenuOpen ? "active" : ""}`}>
      <div className="logo"></div>
      <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
        <a href="/">Početna</a>
        
        <li className="menu-item menu-item-type-post_type menu-item-object-page">
          <HashLink smooth to="/#nase-usluge">
            USLUGE
          </HashLink>
        </li>
        
        <li className="menu-item menu-item-type-post_type menu-item-object-page">
          <HashLink smooth to="/#kontakt">
            KONTAKT
          </HashLink>
        </li>
        <a href="/blog">BLOG</a>
      </ul>
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    </nav>
  );
};

export default NavbarBlog;
