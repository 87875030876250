import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Loader from "./Loader/Loader";
import SmoothScroll from "smooth-scroll";
import Header from "./Header/Header";
import "./Home/Home.css";
import Home from "./Home/Home";
import Navbar from "./Navigation/Navbar";
import Offers from "./Offers/Offers";
import Technologies from "./Technologies/Technologies";
import ContactForm from "./Contact/Contact";
import Footer from "./Footer/Footer";
import HowWeBuild from "./HowWeBuild/HowWeBuild";
import OurClients from "./OurClients/OurClients";
import Blog from "./Blog/Blog";
import Cookie from "./Cookie/Cookie";
import AboutMe from "./AboutMe/AboutMe";

// import Blog from "./Blog/Blog";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const Main = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <div>
          <Navbar />
          <Header />

          <Home />

          <Offers />
         
          <Technologies />
          
          <HowWeBuild />
          
          <OurClients />
          
          <Blog />
          
          <AboutMe />
          <ContactForm />

          <Footer />
          <Cookie />
        </div>
      )}
    </div>
  );
};

export default Main;
