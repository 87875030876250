import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import BlogDetails from "./components/Blog/BlogDetails";
import BlogList from "./components/Blog/BlogList";
import PageNotFound from "./components/PageNotFound/PageNotFound";



function MainRouter() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/blog/:id" element={<BlogDetails/>} />
      <Route path="/lista-objava" element={<BlogList/>} />
      <Route path="/blog" element={<BlogList/>} />
     
      

    </Routes>
  );
}

export default MainRouter;
