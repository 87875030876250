import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Slider.css";
import { Parallax, Pagination, Navigation, Autoplay } from "swiper/modules";

export default function Slider() {
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        speed={600}
        parallax={true}
        autoplay={{
          delay: 6500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Parallax, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <div
          slot="container-start"
          className="parallax-bg"
          data-swiper-parallax="-23%"
        ></div>
        <SwiperSlide>
          <div className="title" data-swiper-parallax="-300">
            <span style={{ color: "#17560c" }}>E</span>-commerce
          </div>

          <div className="text" data-swiper-parallax="-100">
            <p>
              E-commerce, ili online prodavnice, predstavljaju dinamičan i
              efikasan način za prodaju proizvoda ili usluga putem interneta.
              Izrađujući e-commerce sajt, pružate svojim klijentima praktičnost
              online kupovine, proširujete tržište prodaje i otvarate vrata
              globalnom prisustvu vašeg biznisa.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="title" data-swiper-parallax="-300">
            <span style={{ color: "#17560c" }}>K</span>orporativne web stranice
          </div>

          <div className="text" data-swiper-parallax="-100">
            <p>
              Stranice koje koriste kompanije ili organizacije da predstave
              svoje proizvode, usluge, misiju i vrijednosti. Mogu sadržavati
              informacije o timu, kontaktne podatke i druge relevantne
              informacije.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="title" data-swiper-parallax="-300">
            <span style={{ color: "#17560c" }}>S</span>tranice za rezervaciju
          </div>

          <div className="text" data-swiper-parallax="-100">
            <p>
              Stranice koji omogućavaju korisnicima da rezervišu usluge ili
              smeštaj i predstave svoj objekat u najboljem svjetlu.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="title" data-swiper-parallax="-300">
            <span style={{ color: "#17560c" }}>B</span>logovi
          </div>

          <div className="text" data-swiper-parallax="-100">
            <p>
              Web sajtovi posvećeni objavljivanju članaka, informacija ili
              ličnih misli. Blogovi su često organizovani po datumima i često se
              koriste kao sredstvo za djeljenje iskustava ili informacija.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
