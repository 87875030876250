import React from "react";
import Slider from "react-slick";
import { FaBriefcase, FaCode, FaMobileAlt } from "react-icons/fa";
import {
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
  RiNumber6,
  RiNumber7,
} from "react-icons/ri";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HowWeBuild.css";

const HowWeBuild = () => {
  const steps = [
    {
      iconnum: <RiNumber1 />,

      icon: <FaBriefcase />,
      title: "Analiza Potreba",
      description:
        "Prije svega, razumijem vaše poslovne ciljeve i potrebe ciljane publike.",
    },
    {
      iconnum: <RiNumber2 />,
      icon: <FaCode />,
      title: "CMS Pristup",
      description:
        "Razumijem da jedno rješenje ne odgovara svima. Stoga, pristupam izradi web stranica s fleksibilnim CMS sistemima poput WordPressa, omogućavajući vam lako upravljanje i ažuriranje sadržaja bez teškoća.",
    },

    {
      iconnum: <RiNumber3 />,
      icon: <FaMobileAlt />,
      title: "Responsive Dizajn",
      description:
        "Osiguravam da vaša stranica izgleda dobro na različitim uređajima.",
    },
    {
      iconnum: <RiNumber4 />,
      icon: <FaCode />,
      title: "Jasna navigacija",
      description:
        "Dizajniram navigaciju kako bi posjetitelji lahko mogli pronaći informacije.",
    },
    {
      iconnum: <RiNumber5 />,
      icon: <FaCode />,
      title: "Optimizacija web stranice",
      description:
        "Stranice koje nisu optimizirane kradu posjetiteljima vrijeme i uništavaju strpljenje.",
    },
    {
      iconnum: <RiNumber6 />,
      icon: <FaCode />,
      title: "Vizualni identitet brenda",
      description:
        "Vizualni identitet brenda obuhvata sve vizualne elemente koji predstavljaju i prepoznaju određeni brend.",
    },
    {
      iconnum: <RiNumber7 />,
      icon: <FaCode />,
      title: "Testiranje korisničkog iskustva (UX)",
      description:
        "Testiranja korisničkog iskustva kako bi identificirali eventualne probleme koje posjetitelji mogu imati na stranici.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className="howwebuild" id="pristup">
      <div className="container">
      
      <Slider {...settings} className="how-we-build-container">
        {steps.map((step, index) => (
          <div key={index} className="step">
            {step.iconnum}
            {step.icon}

            <h4 style={{ marginTop: "20px" }}>{step.title}</h4>
            <p>{step.description}</p>
          </div>
        ))}
      </Slider>
      </div>
    </div>
  );
};

export default HowWeBuild;
