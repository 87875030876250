import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import "./Cookie.css";

const Cookie = () => {
  // eslint-disable-next-line
  const [cookieAccepted, setCookieAccepted] = useState(
    localStorage.getItem("cookieConsent") === "true"
  );

  const handleAccept = () => {
    setCookieAccepted(true);
    localStorage.setItem("cookieConsent", "true");
  };

  const handleDecline = () => {
    setCookieAccepted(false);
    localStorage.setItem("cookieConsent", "false");
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="cookieConsent"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
    >
      Ova web stranica koristi kolačiće za poboljšanje korisničkog iskustva.{" "}
    </CookieConsent>
  );
};

export default Cookie;
