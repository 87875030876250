import React, { useState, useEffect } from "react";
import "./Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isNavbarVisible, setNavbarVisible] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClickScroll = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.1) {
        setNavbarVisible(true);
      } else {
        setNavbarVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbaranc ${isNavbarVisible ? "visible" : ""} ${
        isMenuOpen ? "active" : ""
      }`}
    >
      <div className="logo"></div>
      <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
      <a href="/">Početna</a>
       
        <li onClick={() => handleClickScroll("nase-usluge")}>USLUGE</li>

        <li onClick={() => handleClickScroll("kontakt")}>KONTAKT</li>
        <a href="/blog">BLOG</a>
      </ul>
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    </nav>
  );
};

export default Navbar;
