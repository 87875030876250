import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Link, useNavigate } from "react-router-dom";
import "./Blog.css";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const navigate = useNavigate();
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN
  });

  useEffect(() => {
    const getAllEntries = async () => {
      try {
        const response = await client.getEntries({
          order: '-fields.napraviDatum',
          content_type: 'blog'
        });
    
        if (response && response.items) {
          // Get the last three entries
          const lastThreeEntries = response.items.slice(0, 3);
          setBlogPosts(lastThreeEntries);
        } else {
          console.error("Invalid response from Contentful:", response);
        }
      } catch (error) {
        console.error("Error fetching entries from Contentful:", error);
      }
    };

    getAllEntries();
  }, [client]);

  const handleLinkClick = (postId) => {
    // Scroll to the top of the page when a link is clicked
    window.scrollTo(0, 0);
    // Navigate to the specified post
    navigate(`/blog/${postId}`);
  };

  return (
    <>
    <div className="blog-bg">
      <div className="blog-main-glavna" id="najnoviji-blogovi">
        <div className="container">
        <div className="naslov-blog">
          
          <h1>NAJNOVIJI Blogovi</h1>
        </div>

        <div className="blog-cards">
          <div className="bloglist-container">
            {blogPosts.map((post) => (
              <div key={post.sys.id} className="bloglist">
                <img
                  src={post.fields.slikaBloga.fields.file.url}
                  alt={post.fields.naslovBloga}
                />

                <div className="naslov">
                  <Link
                    to={`/blog/${post.sys.id}`}
                    onClick={() => handleLinkClick(post.sys.id)}
                  >
                    <h4>{post.fields.naslovBloga}</h4>
                  </Link>
                </div>
                <p>{post.fields.sazetakBloga}</p>
                <div className="blog-end">
                  <Link
                    to={`/blog/${post.sys.id}`}
                    onClick={() => handleLinkClick(post.sys.id)}
                  >
                    Saznajte više »
                  </Link>
                  <p>
                    <small>
                      {new Intl.DateTimeFormat("en-GB", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                      }).format(new Date(post.fields.napraviDatum))}
                    </small>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default Blog;
