import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
import "./Blog.css";
import "./BlogList.css";
import NavbarBlog from "../BlogNavigaton/NavbarBlog";
import FooterBlog from "../BlogFooter/FooterBlog";

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all"); 

  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN
  });

  useEffect(() => {
    const getAllEntries = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "blog", 
          order: "-fields.napraviDatum", 
          
          ...(selectedCategory !== "all" && {
            "fields.category": selectedCategory,
          }),
        });
        setBlogPosts(entries);
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
      }
    };
    getAllEntries();
  }, [client, selectedCategory]);

  
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
    <div className="blog-bg">
      <div className="blog-main" id="section-2">
        <NavbarBlog />
        <div className="container">
        <div className="naslov-bloga">
          <hr className="separator" style={{ width: "40%" }} />
          <h1>Blog</h1>
        </div>
        <div className="category-filter">
          <label htmlFor="category">Odaberi kategoriju: </label>
          {" "}
          <select
            id="category"
            value={selectedCategory}
            onChange={(e) => handleCategoryChange(e.target.value)}
          >
            <option value="all">Svi</option>
            <option value="web">Web</option>
            <option value="zanimljivosti">Zanimljivosti</option>
          </select>
        </div>
        <div className="blog-cards">
          <div className="bloglist-container">
            {blogPosts?.items?.map((post) => (
              <div key={post.sys.id} className="bloglist">
                <img
                  src={post.fields.slikaBloga.fields.file.url}
                  alt={post.fields.naslovBloga}
                />

                <div className="naslov">
                  <Link to={`/blog/${post.sys.id}`}>
                    <h4>{post.fields.naslovBloga}</h4>
                  </Link>
                </div>
                <p>{post.fields.sazetakBloga}</p>
                <div className="blog-end">
                  <Link to={`/blog/${post.sys.id}`}>Saznajte više »</Link>
                  <p>
                    <small>
                      {new Intl.DateTimeFormat("en-GB", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                      }).format(new Date(post.fields.napraviDatum))}
                    </small>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      
      
      </div>
      <div className="footer-others">
        <FooterBlog />
      </div>
      </div>
    </>
  );
};

export default BlogList;
