import React from "react";
import "./AboutMe.css";

import edin from "../../assets/EdinNoBG.png";

function AboutMe() {
  return (
    <div className="about-me-main">
      <section className="section about-section gray-bg" id="o-nama">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6">
              <div className="about-text go-to">
                <h3 className="dark-color">O meni</h3>
                <h6 className="theme-color lead">Web Developer</h6>
                <p>
                  <mark>Dizajniram i kodiram</mark> web stranice svih vrsta.
                  Posvećen sam stvaranju inovativnih i efikasnih web rješenja.
                  Moj pristup dizajnu i razvoju web stranica kombinuje estetsku
                  kreativnost sa stručnim znanjem kako bih stvorio web prisustvo
                  koje ističe identitet mojih klijenata.
                </p>
                <div className="row about-list">
                  <div className="col-md-6">
                    <div className="media">
                      <label>Ime</label>
                      <p>Edin</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="media">
                      <label>E-mail</label>
                      <p>pulsecodeweb@gmail.com</p>
                    </div>
                    <div className="media">
                      <label>Telefon</label>
                      <p>+387 61 338 354</p>
                      <p>+49 163 9775385</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-avatar">
                <img src={edin} title="" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default AboutMe;
