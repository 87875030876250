import "../Header/Header.css";
import "aos/dist/aos.css";
import React, { useState, useEffect } from "react";
import pulsevideo from "../../assets/pulse_code-no.mp4";
import { TypeAnimation } from "react-type-animation";

const Header = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop;
      const percent = (scrollTop / scrollHeight) * 100;
      setScrollPercentage(percent);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (isMobile) {
    return (
      <div className="mobile-header" loading="lazy">
        <h1>PULSECODE </h1>
        <h1>DEVELOPMENT</h1>
        <TypeAnimation
          sequence={[
            
            "Kreiramo digitalnu harmoniju.",
            2000,
            "Dizajniramo, Razvijamo, Ostvarujemo Vaše Online Ambicije.",
            2000,
          ]}
          wrapper="p"
          speed={50}
          repeat={Infinity}
        />
      </div>
    );
  }

  return (
    <div
      className="header"
      style={{ opacity: scrollPercentage === 100 ? 0 : 1 }}
    >
      <video autoPlay loop muted className="header-video" loading="lazy">
        <source src={pulsevideo} type="video/mp4" />
      </video>

      <div className="header-text">
        <h1 data-aos="zoom-in">PULSECODE DEVELOPMENT</h1>
        <p data-aos="fade-up">Kreiramo digitalnu harmoniju.</p>
      </div>
    </div>
  );
};

export default Header;
