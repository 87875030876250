import React from "react";
import "./Graphic.css";
import "./Graphic.scss";
import "./OurClients.css";
import abpetrol from "../../assets/ab-petrol.png"
import annt from "../../assets/annt.png"
import logoboiken from "../../assets/logoboiken.webp";
import grafiklogo from "../../assets/logoonly.webp";
import delft from "../../assets/delftlogo.webp";

const OurClients = () => {




  return (
    <div className="our-cover" >
      <div className="container">
    <div className="our-main" >
      <div className="naslov-klijenti">
       
        <h1>Moji klijenti</h1>
      </div>
      <div className="graphic-logo" >
        <ul className="logogrid">
          <li className="logogrid__item">
            <img src={logoboiken} className="logogrid__img" alt="boiken" />
          </li>
          <li className="logogrid__item">
            <img src={delft} className="logogrid__img" alt="delft" />
          </li>
          <li className="logogrid__item" >
            <img  src={grafiklogo} className="logogrid__img" alt="grafik" />
          </li>
          <li className="logogrid__item" >
            <img  src={annt} className="logogrid__img" alt="annt-logo" />
          </li>
          <li className="logogrid__item" >
            <img  src={abpetrol} className="logogrid__img" alt="abpetrol-logo" />
          </li>
        </ul>
       
      </div>
      
    </div>
    </div>
    </div>
  );
};

export default OurClients;
