import React, { useEffect } from "react";
import "./Technologies.css";
import reactlogo from "../../assets/reactjs.png";
import wlogo from "../../assets/wordpress.webp";
import technoimg from "../../assets/tecno-hand.webp"
import webflowlogo from "../../assets/webflow.webp";
import Aos from "aos";
const Technologies = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="techno-main" id="tehnologije">
      
      <div className="technoimg"> <img src={technoimg} alt="techno"/></div>
      
      <div className="naslov-tehnologije">
        <h1>TEHNOLOGIJE KOJE KORISTIM</h1>

        <div className="image-row" data-aos="flip-down">
          <div className="image-container">
            <img className="rounded-image" src={reactlogo} alt="react" />
            <p className="image-title">ReactJS</p>
          </div>

          <div className="image-container">
            <img className="rounded-image" src={wlogo} alt="wordpress" />
            <p className="image-title">Wordpress</p>
          </div>

          <div className="image-container">
            <img className="rounded-image" src={webflowlogo} alt="webflow" />
            <p className="image-title">WebFlow</p>
          </div>
        </div>
      </div>
      </div>
    
  );
};

export default Technologies;
