import "./Offers.css";
import "aos/dist/aos.css";
import Aos from "aos";
import React, { useEffect } from "react";
import Slider from "../Slider/Slider";

const Offers = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <div className="offers-main" id="nase-usluge">
      <div className="container">
      <div className="offers-slider">
        <Slider />
      </div>
      </div>
    </div>
  );
};

export default Offers;
