import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import "./Contact.css";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isMessageSent, setMessageSent] = useState(false);

  const onSubmit = (data) => {
    const serviceID = "service_9mzlejn";
    const templateID = "template_xpnriyf";
    const userID = "8jQ-HlaUNCytl6S1j";

    data.category = data.category || "";

    emailjs
      .send(serviceID, templateID, data, userID)
      .then((response) => {
        console.log("Email sent:", response);
        reset(); // Resetiranje polja forme nakon uspješnog slanja
        setMessageSent(true);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <>
     <div className="contact">
      
      <div className="contact-main" id="kontakt">
      
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <label>Ime:</label>
          <input {...register("name", { required: "Ime je obavezno" })} />
          {errors.name && <p>{errors.name.message}</p>}

          <label>Email:</label>
          <input
            {...register("email", {
              required: "Email je obavezan",
              pattern: { value: /^\S+@\S+$/i, message: "Neispravan email" },
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}

          <label>Broj telefona:</label>
          <input {...register("phone")} />

          <label>Vrsta usluge:</label>
          <select
            {...register("category", {
              required: "Odabir vrste stranice je obavezan",
            })}
            className="form-select"
            aria-label="Default select example"
          >
            <option value="1">KORPORATIVNA WEB STRANICA</option>
            <option value="2">STRANICA ZA REZERVACIJU</option>
            <option value="3">BLOG</option>
            <option value="4">E-COMMERCE</option>
            <option value="5">OSTALO</option>
          </select>
          {errors.category && <p>{errors.category.message}</p>}

          <label>Poruka:</label>
          <textarea
            {...register("message", { required: "Poruka je obavezna" })}
          />
          {errors.message && <p>{errors.message.message}</p>}

          {isMessageSent ? (
            <p className="success-message" style={{ color: "green" }}>
              Vaša poruka je poslana uspješno!
            </p>
          ) : (
            <button type="submit">Pošalji</button>
          )}
        </form>
      </div>
      </div>
    </>
  );
};

export default ContactForm;
